export default {
  en: {
    common: {
      bookNow: 'Book now',
      bookAppointment: 'Book an appointment',
      findOutMore: 'Find out more',
      newsLetter: 'Get the newsletter',
      visitQigong: 'Visit the Qigong Site',
      next: 'Next',
      emailAddress: 'Email Address',
      firstName: 'First name',
      lastName: 'Last name',
      legal: 'All rights reserved.'
    },
    nav: {
      formations: 'Formations'
    },
    address: {
      laurierMetro: 'Laurier Station'
    },
    qigong: {
      title:
        'The mission of Centre Qigong Laflamme is to help people become proactive in their journey towards better health.',
      content:
        'Please note that classes and training are currently suspended. Subscribe to the newsletter to keep up to date! You can also make an appointment for an acupuncture consultation via'
    }
  },
  fr: {
    common: {
      bookNow: 'Rendez-Vous',
      bookAppointment: 'Prendre rendez-vous',
      findOutMore: 'En savoir plus',
      newsLetter: "S'inscrire à l'infolettre",
      visitQigong: 'Visitez le Site Qigong',
      next: 'Suivant',
      emailAddress: 'Adresse courriel',
      firstName: 'Prénom',
      lastName: 'Nom',
      legal: 'Tous droits réservés.'
    },
    nav: {
      formations: 'Formations'
    },
    address: {
      laurierMetro: 'Station Laurier'
    },
    qigong: {
      title: `La mission du Centre de Qigong Laflamme est d'aider les gens à devenir proactifs dans leur cheminement vers une meilleure santé.`,
      content: `Veuillez prendre notes que les cours et les formations sont présentement suspendus. Inscrivez vous à l'info-lettre pour vous tenir au courant! Vous pouvez aussi prendre rendez-vous pour une consultation en acupuncture via`
    }
  }
}
