import http from 'axios'
import _get from 'lodash.get'

import { reduceDropdownData } from './reducers'
import { createCachedRef, getQueryString, parseKey } from './helpers'
import localStorage from '@/localStorage'

const endpoint = 'https://acupuncture.prismic.io/api/v2'

export default (Vue, { defaultLang = 'fr', i18n }) => {
  const getRef = createCachedRef(endpoint)

  const PrismicVM = new Vue({
    name: 'Prismic',
    data() {
      return {
        defaultLang,
        lang: defaultLang,
        isReady: false,
        store: {}
      }
    },
    computed: {
      content() {
        return Object.keys(this.store).reduce((content, key) => ({ ...content, [key]: this.store[key][this.lang] }), {})
      }
    },
    watch: {
      lang: {
        immediate: true,
        handler(newLang) {
          localStorage.setItem('lang', newLang)
          document.documentElement.lang = newLang
        }
      }
    },
    created() {
      getRef().then(ref => {
        const query = getQueryString({ ref, q: `[[at(document.type,"home_page")]]` })

        http(`${endpoint}/documents/search?${query}`).then(({ data = {} }) => {
          const { results = [] } = data
          const [{ data: content = {} }] = results
          const { about_dropdown: aboutDropdown, acupuncture_dropdown: acupunctureDropdown } = content

          this.store = Object.keys(content)
            .filter(key => /^[^_]/.test(key) && /^(en|fr)_/.test(key))
            .reduce(
              (contentMap, key) => {
                const { langKey, contentKey } = parseKey(key)
                const currentContent = contentMap[contentKey] || {}

                return {
                  ...contentMap,
                  [contentKey]: {
                    ...currentContent,
                    [langKey]: content[key]
                  }
                }
              },
              {
                aboutDropdowns: reduceDropdownData(aboutDropdown),
                acupunctureDropdowns: reduceDropdownData(acupunctureDropdown)
              }
            )

          this.isReady = true
        })
      })
    }
  })

  Vue.prototype.$prismic = PrismicVM

  Vue.mixin({
    computed: {
      $c() {
        return this.$prismic.content
      }
    },
    methods: {
      $t(path) {
        return _get(i18n[this.$prismic.lang], path, path)
      }
    }
  })
}
