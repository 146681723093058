<template>
  <section class="home-header">
    <nav class="home-header__nav">
      <figure  class="navigation-top__logo" @click="$scroll.toTarget('#home', 50, 350)">
        <img class="navigation-top__img" src="/images/logo.png" alt="" />

        <div class="navigation-top__heading">
          <h1>Centre Qigong<strong>Laflamme</strong></h1>
        </div>
      </figure>
    </nav>

    <header class="home-header__content">
      <h2 v-scroll:reveal class="home-header__banner">{{ $t('qigong.title') }}</h2>
      <p v-scroll:reveal="{delay:100}">{{ $t('qigong.content') }} <a href="https://www.gorendezvous.com/en/laflamme?gclid=Cj0KCQjwhY-aBhCUARIsALNIC075jFIBsFk7j17NaSJkbfp384tt2wn8Otd9zKPRBQfu9HLeA9Rz0VUaAmTZEALw_wcB" class="link -accent">GOrendezvous</a>.</p>

      <form v-scroll:reveal="{delay:200}" class="layout-form -header" @submit.prevent="onSubmit">
        <input v-model="model.email" type="text" :placeholder="$t('common.emailAddress')" class="input -newsletter" />
        <button href="https://www.gorendezvous.com/homepage/120424" target="_blank" rel="noopener" class="button -newsletter">{{ $t('common.newsLetter') }}</button>
      </form>
    </header>

    <ModalNewsletter v-if="isModalVisible" :email="model.email" @close="isModalVisible = false" />

    <footer class="navigation-footer">
      <p>{{ currentYear }} &copy; Centre Qigong Laflamme. {{ $t('common.legal') }}</p>

      <div class="navigation-footer__options">
        <RouterLink to="/" class="navigation-footer__lang -active">Français</RouterLink>
        <span>/</span>
        <RouterLink to="/en" class="navigation-footer__lang">English</RouterLink>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'Home',
  props: {
    lang: { type: String, default: 'en' }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isModalVisible: false,
      model: {
        email: '',
        name: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.isModalVisible = true
    }
  }
}
</script>
