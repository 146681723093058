<template>
  <menu class="navigation-header">
    <router-link to="/" tag="figure" class="navigation-top__logo">
      <img class="navigation-top__img" src="/images/logo.jpg" alt="" />

      <div class="navigation-top__heading">
        <h1>Acupuncture<strong>Sean Laflamme</strong></h1>
      </div>
    </router-link>

    <ul class="navigation-header__options">
      <li class="navigation-header__item" @click="$scroll.toTarget('#acupuncture', 50, 350)">Qigong</li>
      <li class="navigation-header__item" @click="$scroll.toTarget('#service', 50, 350)">{{ $t('nav.formations') }}</li>
      <li class="navigation-header__item" @click="$scroll.toTarget('#about', 50, 350)">Sean Laflamme</li>
      <a class="navigation-header__item"  @click="$scroll.toTarget('#qigong', 50, 350)">Acupuncture</a>

      <NavigationTopLangButton />
    </ul>
  </menu>
</template>

<script>
import NavigationTopLangButton from './NavigationTopLangButton.vue'

export default {
  name: 'NavigationHeader',
  components: { NavigationTopLangButton }
}
</script>
