import './sass/app.scss'
import './startup'

import Vue from 'vue'
import DeviceQueries from 'vue-device-queries'

import i18n from './i18n'
import App from './App.vue'
import router from './router'
import Prismic from './prismic'
import MailChimp from './plugins/mailchimp'
import ScrollHub from './plugins/scrollHub'
import ScrollUtils from './plugins/ScrollUtils'
import { GlobalComponents } from './components'

const offset = window.innerHeight * 0.1
const scrollingElement = document.scrollingElement || document.documentElement
const defaultLang = 'fr'
document.documentElement.lang = defaultLang

Vue.config.productionTip = false

Vue.use(Prismic, { defaultLang, i18n })
Vue.use(ScrollHub, { scrollingElement, scrollEventTarget: window, reveal: { offset }, trigger: { offset } })
Vue.use(ScrollUtils, { scrollEventTarget: window })
Vue.use(GlobalComponents)
Vue.use(DeviceQueries, { laptop: `min-width: 1088px`, tablet: `min-width: 768px`, tabletH: `min-width: 900px` })

Vue.use(MailChimp, {
  url: 'https://qigonglaflamme.us12.list-manage.com/subscribe/post-json',
  userId: 'b194b187fe1de9c2880968587',
  listId: 4515341670
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
