import jsonp from 'jsonp'
import queryString from 'query-string'

export default (Vue, { url, userId, listId }) => {
  Vue.prototype.$mailchimp = new Vue({
    data() {
      return {
        error: null,
        isLoading: false
      }
    },
    methods: {
      subscribe({ email: EMAIL, firstName: FNAME, lastName: LNAME }) {
        if (this.loading || !EMAIL || !FNAME || !LNAME) return

        this.error = null
        this.loading = true

        const data = queryString.stringify({ u: userId, id: listId, EMAIL, FNAME, LNAME })

        return new Promise((resolve, reject) => {
          jsonp(`${url}?${data}`, { param: 'c' }, (err, res) => {
            this.loading = false
            this.error = res && res.result === 'error' ? this.formatErrorMessage(res.msg) : err
            if (this.error) return reject(this.error)
            resolve()
          })
        })
      },
      formatErrorMessage(message) {
        return message.replace('0 - ', '')
      }
    }
  })
}
