<template>
  <footer class="navigation-footer">

    <div class="navigation-footer__bottom">
      <p>{{ currentYear }} &copy; Centre Qigong Laflamme</p>
      <p>Français / English</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      links: [
        { name: 'home', label: 'Home' },
        { name: 'about', label: 'About' }
      ]
    }
  }
}
</script>
