<template>
  <div v-if="$prismic.isReady" class="layout">
    <Transition name="layout__view" mode="out-in">
      <RouterView :key="$route.name" class="layout__view" />
    </Transition>
    <!-- <NavigationTop @toggle-sidenav="isSideNavOpen = !isSideNavOpen" /> -->
    <!-- <NavigationSide :is-open="isSideNavOpen" @close="isSideNavOpen = false" /> -->
  </div>
</template>

<script>
import localStorage from '@/localStorage'

const storedLang = localStorage.getItem('lang')

export default {
  name: 'App',
  data() {
    return {
      isSideNavOpen: false
    }
  },
  watch: {
    '$route.params.lang': {
      immediate: true,
      handler(lang) {
        this.$prismic.lang = lang || this.$prismic.defaultLang
      }
    }
  },
  created() {
    if (!storedLang || storedLang === this.$prismic.lang) return
    const path = storedLang === this.$prismic.defaultLang ? '' : storedLang
    this.$router.push(`/${path}`)
  }
}
</script>
